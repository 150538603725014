.img-maria {
  height: 30vmin;
  pointer-events: none;
  margin-left: 3vmin;
  margin-right: 3vmin;
}

.img-ballons {
  height: 15vmin;
  pointer-events: none;
}

@keyframes img-maria-rotate {
  from {
    transform: rotate(-12deg);
  }
  to {
    transform: rotate(12deg);
  }
}

@keyframes img-ballons-rotate {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(-10deg);
  }
}

@keyframes img-ballons-rise {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-200%);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .img-maria {
    animation: img-maria-rotate infinite 3s linear alternate-reverse;
  }
  .img-ballons-translate {
    animation: img-ballons-rise infinite 6s ease-in alternate-reverse;
  }
  .img-ballons-rotate {
    animation: img-ballons-rotate infinite 3s linear alternate-reverse;
  }

  .img-ballons-rotate2 {
    animation: img-ballons-rotate infinite 3100ms linear alternate-reverse;
  }

}
